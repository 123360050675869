import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pteen/_src/a4i/website/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Terms of service`}</h1>
    <h2>{`1. Terms`}</h2>
    <p>{`By accessing the `}<strong parentName="p">{`Analytics for Intercom app`}</strong>{` at `}<a parentName="p" {...{
        "href": "http://analyticsforintercom.com/"
      }}>{`http://analyticsforintercom.com/`}</a>{`, you are
agreeing to be bound by these terms of service written by
`}<strong parentName="p">{`sown`}</strong>{` (`}<a parentName="p" {...{
        "href": "http://sown.app/"
      }}>{`http://sown.app/`}</a>{`), the creators of Analytics for Intercom. You agree that you are responsible for compliance with any
applicable local laws. If you do not agree with any of these terms, you
are prohibited from using or accessing this app. The materials contained
in this app are protected by applicable copyright and trademark law.`}</p>
    <h2>2. Use License</h2>
Permission is granted to temporarily download one copy of the materials
(information or software) on sown's app for
personal, non-commercial transitory viewing only. This is the grant of a
license, not a transfer of title, and under this license you may not:
modify or copy the materials; use the materials for any commercial
purpose, or for any public display (commercial or non-commercial); attempt
to decompile or reverse engineer any software contained on the sown app; remove any copyright or other proprietary notations
from the materials; or transfer the materials to another person or
"mirror" the materials on any other server. This license shall
automatically terminate if you violate any of these restrictions and may
be terminated by sown at any time. Upon terminating your
viewing of these materials or upon the termination of this license, you
must destroy any downloaded materials in your possession whether in
electronic or printed format.
    <h2>3. Disclaimer</h2>
The materials on sown's app are provided on an 'as
is' basis. sown makes no warranties, expressed or
implied, and hereby disclaims and negates all other warranties including,
without limitation, implied warranties or conditions of merchantability,
fitness for a particular purpose, or non-infringement of intellectual
property or other violation of rights. Further, sown
does not warrant or make any representations concerning the accuracy,
likely results, or reliability of the use of the materials on its app
or otherwise relating to such materials or on any apps linked to this
app.
    <h2>4. Limitations</h2>
In no event shall sown or its suppliers be liable for
any damages (including, without limitation, damages for loss of data or
profit, or due to business interruption) arising out of the use or
inability to use the materials on sown's app, even
if sown or a sown authorized
representative has been notified orally or in writing of the possibility
of such damage. Because some jurisdictions do not allow limitations on
implied warranties, or limitations of liability for consequential or
incidental damages, these limitations may not apply to you.
    <h2>5. Accuracy of materials</h2>
The materials appearing on sown's app could include
technical, typographical, or photographic errors. sown
does not warrant that any of the materials on its app are accurate,
complete or current. sown may make changes to the
materials contained on its app at any time without notice. However
sown does not make any commitment to update the
materials.
    <h2>6. Links</h2>
sown has not reviewed all of the apps linked to its
app and is not responsible for the contents of any such linked app.
The inclusion of any link does not imply endorsement by Analytics for
Intercom of the app. Use of any such linked app is at the user's own
risk.
    <h2>7. Modifications</h2>
sown may revise these terms of service for its app
at any time without notice. By using this app you are agreeing to be
bound by the then current version of these terms of service.
    <h2>8. Governing Law</h2>
These terms and conditions are governed by and construed in accordance
with the laws of Sydney, Australia and you irrevocably submit to the
exclusive jurisdiction of the courts in that State or location.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      